import '../css/Footer.css';
import CopyrightIcon from '@mui/icons-material/Copyright';

export default function Footer() {
    return (
        <footer>
            <CopyrightIcon style={{margin: 0, color: 'white', fontSize: '18px', marginRight: '8px', fontWeight: 300}}/> 
            <span style={{fontWeight: 300}}>hadikaraki 2024</span>
        </footer>
    )
}