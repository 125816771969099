import * as React from 'react';
import '../css/Navbar2.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ComputerIcon from '@mui/icons-material/Computer';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

export default function Navbar2({ setIframeLink, setChosenWebsite }) {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
  
    const handleDrawerOpen = () => {
      setDrawerOpen(true);
    };
  
    const handleDrawerClose = () => {
      setDrawerOpen(false);
    };
  
    return (
      <nav key="nav">
        <AppBar key="app-bar" position="static" sx={{ zIndex: 2, position: 'fixed', margin: 0, boxShadow: 'none', backgroundColor: 'black' }}>
            <Toolbar id="nav-toolbar" style={{ width: '97.5%', margin: '0 auto', display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
                id='menu-icon'
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>

              {/* Centered Logo and Typography for Mobile View */}
              <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                {/* <ComputerIcon sx={{ color: 'white', mr: 1 }} /> */}
                <Typography
                  key="typography2"
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    fontFamily: 'monospace',
                    color: '#d5d5db',
                    fontWeight: 200,
                    fontSize: '16px',
                    textDecoration: 'none',
                    letterSpacing: '0.4rem'
                  }}
                >
                  HADI KARAKI
                </Typography>
              </Box>

              {/* Full Logo and Typography for Desktop View */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                <ComputerIcon sx={{ mr: 2, color: 'white', fontSize: '16px' }} />
                <Typography
                  key="typography1"
                  variant="p"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    fontFamily: 'monospace',
                    color: '#d5d5db',
                    textDecoration: 'none',
                    letterSpacing: '0.4rem',
                    fontSize: '16px'
                  }}
                >
                  HADI KARAKI
                </Typography>
              </Box>

              {/* LinkedIn and Avatar */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', flexGrow: 1 }}>
                <a href="/projects" style={{ textDecoration: 'none' }}>
                  <Button key="linkedin" sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none', fontSize: '16px'}}>
                    Projects
                  </Button>
                </a>
                <a href="#" style={{ textDecoration: 'none' }}>
                  <Button key="linkedin" sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none', fontSize: '16px'}}>
                    Contact
                  </Button>
                </a>
              </Box>

              <Box sx={{ flexGrow: 0 }} className="mobile-buttons">
                <Tooltip>
                  <IconButton sx={{ p: 0 }}>
                    <a href="https://www.linkedin.com/in/hadi-karaki-375991214/" style={{ textDecoration: 'none' }}>
                      <Button key="linkedin" sx={{ my: 0, color: 'white', display: 'block', textTransform: 'none', fontSize: '16px'}}>
                        <LinkedInIcon/>
                      </Button>
                    </a>
                  </IconButton>
                  <IconButton sx={{ p: 0 }}>
                    <a href="mailto:hadikaraki373@gmail.com" style={{ textDecoration: 'none' }}>
                      <Button key="email" sx={{ my: 0, color: 'white', display: 'block', textTransform: 'none', fontSize: '16px'}}>
                        <EmailIcon/>
                      </Button>
                    </a>
                  </IconButton>
                </Tooltip>
              </Box>
            </Toolbar>
          </AppBar>

  
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerClose}
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: '100%',
                  backgroundColor: 'black',
                  padding: '20px 10px',
                  mr: 0
                },
              }}
            >
            <Button
              onClick={handleDrawerClose}
              className='drawer-burger'
              sx={{
                color: 'white',
                textTransform: 'none',
                alignSelf: 'flex-end',
                mb: 2,
                mr: 0
              }}
            >
              Close
            </Button>

            <Typography
              variant="h6"
              id="projects-link"
              sx={{
                ml: 2,
                mt: 4,
                mb: 1,
                color: 'white',
                fontWeight: '700',
                // backgroundColor: 'grey'
              }}
            >
              <a href="/projects" style={{color: 'white', textDecoration: 'none', borderBottom: '0.1px solid white', paddingBottom: '5px'}}>
                View Projects
              </a>
            </Typography>

            <Typography
              variant="h6"
              sx={{
                ml: 2,
                mt: 1,
                mb: 2,
                color: 'white',
                fontWeight: '700',
              }}
            >
              Skills
            </Typography>
            <List sx={{ ml: 2 }}>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>AJAX</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Docker</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Kubernetes</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Linux</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Scripting</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Nginx</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>MongoDB</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>PHP</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Express</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Node</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>React</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>HTML & CSS</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>MERN stack</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Java</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>JavaScript</span>
              </ListItem>
            </List>
            <div className="resume-btn" style={{marginLeft: '15px'}}>
              <button className="download-button">
                <a href="/resume.pdf" download="resume.pdf">Resume</a>
              </button>
            </div>
            <div className="linkedin-link">
                <a href="https://www.linkedin.com/in/hadi-karaki-375991214/">
                  <LinkedInIcon/>
                </a>
                <a href="mailto:hadikaraki373@gmail.com" style={{marginLeft: '15px'}}>
                  <EmailIcon/>
                </a>
            </div>
          </Drawer>
      </nav>
    );
  }
  