import '../css/Projects.css';
import pharmacy from "../assets/pharmacy.png";
import campsite from "../assets/campsites.png";
import delivery from "../assets/delivery.png";
import ecommerce from "../assets/ecommerce.png";
import hbk from "../assets/hbk.png";

const projectData = [
    { name: "Pharmaceutical Web app", img: pharmacy, link: "https://hadikaraki.net/project/pharmacy/" },
    { name: "Campsites Around the World", img: campsite, link: "https://hadikaraki.net/project/campsites/" },
    { name: "Delivery Service Web app", img: delivery, link: "https://hadikaraki.net/project/delivery/" },
    { name: "E-commerce Web app", img: ecommerce, link: "https://hadikaraki.net/project/ecommerce/" },
    { name: "Airline Reservation System (GitHub Code)", img: hbk, link: "https://hadikaraki.net/project/hbk/" }
];

export default function Projects() {
    return (
        <div className="project-div">
            <div className="projects-content">
                <h2 className="projects-title" style={{fontSize: '15px', marginTop: '8%'}}>PROJECTS</h2>
                <div className="projects">
                    {/* Pharmacy Project */}
                    <div className="project-item">
                        <a href="https://pharmacy.hadikaraki.net">
                            <img src={pharmacy} alt="Pharmacy" />
                        </a>
                        <div className="project-description">
                            <h4>Pharmaceutical Web app</h4>
                            <p>A platform for selling pharmaceutical products using:</p>
                            <ul>
                                <li>Node.js, Express, MongoDB for backend</li>
                                <li>React.js for frontend with Mongo Atlas Search</li>
                                <li>Account features: cart, wishlist, order history</li>
                                <li>Email verification & password recovery using JWT</li>
                                <li>Containerized with Docker and Kubernetes</li>
                            </ul>
                        </div>
                    </div>

                    {/* Campsite Project */}
                    <div className="project-item">
                        <a href="https://campsites.hadikaraki.net">
                            <img src={campsite} alt="Campsite" />
                        </a>
                        <div className="project-description">
                            <h4>Campsites Around the World</h4>
                            <ul style={{ textAlign: 'left' }}>
                                <li>Node.js, Express, MongoDB for backend</li>
                                <li>React.js for frontend with Mongo Atlas Search</li>
                                <li>Account features: cart, wishlist, order history</li>
                                <li>Email verification & password recovery using JWT</li>
                            </ul>
                        </div>
                    </div>

                    {/* Delivery Service Project */}
                    <div className="project-item">
                        <a href="https://delivery.hadikaraki.net">
                            <img src={delivery} alt="Delivery Service" />
                        </a>
                        <div className="project-description">
                            <h4>Delivery Service Web app</h4>
                            <p>Users can explore restaurants, view their menus, and place orders.</p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>Node.js, Express, MongoDB for backend</li>
                                <li>React.js for frontend with Mongo Atlas Search</li>
                                <li>Account features: cart, wishlist, order history</li>
                                <li>Email verification & password recovery using JWT</li>
                            </ul>
                        </div>
                    </div>

                    {/* E-commerce Project */}
                    <div className="project-item">
                        <a href="https://ecommerce.hadikaraki.net">
                            <img src={ecommerce} alt="E-commerce" />
                        </a>
                        <div className="project-description">
                            <h4>E-commerce Web app</h4>
                            <p>A store for selling electronics, with search functionality powered by Mongo Atlas.</p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>Node.js, Express, MongoDB for backend</li>
                                <li>MongoDB Atlas as databse</li>
                                <li>Cart and Wishlist features</li>
                                <li>with Mongo Atlas Search engine to search for products</li>
                            </ul>
                        </div>
                    </div>

                    {/* Airline Reservation Project */}
                    <div className="project-item">
                        <a href="https://github.com/HadiKaraki/HBK-Airways">
                            <img src={hbk} alt="Airline Reservation" />
                        </a>
                        <div className="project-description">
                            <h4>Airline Reservation System (GitHub Code)</h4>
                            <ul style={{ textAlign: 'left' }}>
                                <li>HTML, CSS, JavaScript and PHP as frontend</li>
                                <li>MongoDB as database</li>
                                <li>XAMPP for hosting</li>
                                <li>Search engine that allows users to search for flights</li>
                                <li>Users create accounts, book flights or reserve</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Mobile View */}
                <div className="projects-mobile">
                    <h2 className="projects-title-mobile" style={{fontSize: '15px', marginTop: '20%'}}>PROJECTS</h2>
                    {projectData.map((project, index) => (
                        <div className="project-item-mobile" key={index}>
                            <div className='item-mobile-img'>
                                <a href={project.link}>
                                    <img src={project.img} alt={project.name} />
                                </a>
                            </div>
                            <div className='item-mobile-name'>
                                <p>
                                    {project.name}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
